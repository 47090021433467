<template>
    <DxDataGrid
        ref="myDetailDataGrid"
        :data-source="dataEquipo"
        @row-updating="updateRow"
        @init-new-row="onNewRow"
    >
        <DxColumn 
            caption="Usuario"
            data-field="te_UserId"
            data-type="number"
            width=600
        >
            <DxLookup
                :data-source="dataUsuarios"
                value-expr="us_Id"
                display-expr="us_Nombre"
            />
        </DxColumn>
        <DxColumn 
            caption="Horas"
            data-field="te_Horas"
            data-type="number"
            :min-width=50
        >
        </DxColumn>
        <DxColumn 
            caption="Tarifa"
            data-field="te_Tarifa"
            data-type="number"
            :min-width=100
        >
        </DxColumn>
        <DxColumn
            caption="Fee"
            :calculate-display-value="getFee"
            data-type="number"
        >
        </DxColumn>
        <DxColumn
            data-field="te_Terreno"
            data-type="boolean"
            :width=50
            header-cell-template="title-header-terrain"
        >
        </DxColumn>
        <template #title-header-terrain>         
          <i class="bi bi-sign-intersection-t"></i>
        </template>
        <DxColumn type="buttons">
            <DxButton
            icon="add"
            @click="onAddButtonClick"
            />
            <DxButton name="edit"/>
            <DxButton name="delete"/>
            <DxButton name="save"/>
            <DxButton name="cancel"/>
        </DxColumn>
        <DxEditing
            :allow-updating="true"
            :allow-deleting="true"
            :texts="localizedEditTexts"
            :use-icons="true"
            refresh-mode="repaint"
            :select-text-on-edit-start="true"
            mode="cell"
        >
        </DxEditing>
    </DxDataGrid>
</template>

<script setup>
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'

import { DxDataGrid,
        DxColumn, 
        DxEditing, 
        DxButton,
        DxLookup, 
    } from 'devextreme-vue/data-grid'

import { ref } from 'vue'

const myDetailDataGrid = ref(null)

const localizedEditTexts = {
    confirmDeleteMessage: '¿Esta seguro que desea eliminar esta información?'
}

const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'

const props = defineProps({
    templateData: { type: Object },
    gridinstance: { type: Object },
})

const updateObject = ref()
const selectedItemKeys = ref(null)

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

function upedateamos() {
    let ds = props.gridinstance.instance
    ds.refresh()
}

const dataEquipo = new DataSource({
  store: new CustomStore({
    key: 'te_Id',
    byKey: async (keyVal) => {
      try {
      const response = await fetch( baseUrl + 'tarifaConsultor/' + keyVal);
      return await response.json();
    } catch {throw 'Problema en la Conexión de Red'}
    },
    load: () => {
      let url = baseUrl + 'tareasEquipo/308'
      return fetch(url)
        .then(handleErrors)
        .then(resp => resp.json())
        .catch(() => { throw 'Problema en la Conexion de Red'})
    },
    update: (key) => {
        var newBody = {
            userId: updateObject.value.te_UserId,
            horas: updateObject.value.te_Horas,
            tarifa: updateObject.value.te_Tarifa,
            terreno: updateObject.value.te_Terreno,
            id: key
            }
        return fetch(baseUrl + "tareasEquipo", {
            method: 'POST',
            body: JSON.stringify(newBody)
        })
        .then(handleErrors)
        .then(upedateamos())
    },
    insert: (value) => {
        var newBody = {
            projectId: value.te_ProjectId,
            tareaId: value.te_TareaId,
            userId: value.te_UserId,
            horas: 0,
            tarifa: value.te_Tarifa,
            terreno: 0,
            }

        dataEquipo.store().byKey(value.te_UserId)
            .then(data => {
                newBody.tarifa = data[0].tc_Importe
            })
            .then(() => fetch(baseUrl + "tareasEquipo", {
                    method: 'PUT',
                    body: JSON.stringify(newBody)
                })
            .then(handleErrors)
            .then(upedateamos()))


        // detailStore.byKey(value.tareaId)
        //     .then(data => {
        //         newBody.property1 = data[0].taProperty1
        //         newBody.property2 = data[0].taProperty2
        //     })
        //     .then(() => fetch(props.baseurl + "inserttime", {
        //             method: 'POST',
        //             body: JSON.stringify(newBody)
        //             })
        //             .then(handleErrors)
        //             .then( ()=> upedateamos())
        //             .catch( () => {} ))


    },
    remove: (key) => {
        return fetch( baseUrl + "tareasEquipo?id="+key, {
            method: "DELETE"
        })
        .then(handleErrors)
        .then(upedateamos())
}
  }),
  filter: ['te_TareaId', '=', props.templateData.data.pt_Id]
})

const dataUsuarios = new CustomStore({
    key: 'us_Id',
    loadMode: 'raw',
    load: () => {
    let url = baseUrl + 'user/0'
    return fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .catch(() => { throw 'Problema en la Conexión de Red' + url})
    }
})


function updateRow(e) { 
    if (!e.cancel) {
        for (var property in e.oldData) {
        if (!Object.prototype.hasOwnProperty.call(e.newData,property)) {
            e.newData[property] = e.oldData[property];
        }    
        }
        updateObject.value = e.newData
    }  
}

function onNewRow(e) {
  e.data.te_ProjectId = selectedItemKeys.value.te_ProjectId
  e.data.te_TareaId = selectedItemKeys.value.te_TareaId
}

function onAddButtonClick(e) {
    selectedItemKeys.value = e.row.data
    myDetailDataGrid.value.instance.addRow()
}

function getFee(rowData) {
    return rowData.te_Tarifa * rowData.te_Horas
}
</script>