import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function (data, grilla, checklist) {
    const blanco = {
    border: [false, false, false, false],
    text: ''
    }
    
    const layoutCampo = {
        fillColor: function (rowIndex, node, columnIndex) {
            node.style = 'tableExample'
            return (columnIndex % 2 === 0 & rowIndex === 1) ? '#F2F2F2' : null;
        },
        hLineWidth: function () {
            return 0;
        },
        vLineWidth: function () {
            return 0;
        },
        paddingTop: function() { 
            return 5; 
        },
        paddingBottom: function() {
            return 5;
        },
        paddingRight: function() {
            return 5;
        }
    }
    let formatImporte = null;

    if (data.tipoMonedaId == 0)
      formatImporte = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 0
      })
    else 
      formatImporte = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      })

    var bodyGrillaCheckList = []

    let meses = isNaN(data.meses) ? 'S/D' : data.meses
     
    let fechaFin = data.fechaFin == null ? 'S/D' : Intl.DateTimeFormat('es-AR', {year:'numeric', month:'2-digit', day:'2-digit'}).format(data.fechaFin)


    for (var key1 in checklist) {
      var dataCheckList = checklist[key1]
      var row1 = new Array()
      var texto = {}
      texto.text = dataCheckList.text
      texto.border = [false,false,false,true]
      texto.borderColor = ['', '', '', '#F2F2F2']
      texto.style = 'fieldData'

      row1.push(texto);

      texto = {}
      texto.text = dataCheckList.value ? '√' : ''
      texto.color = '#D26E3B'
      texto.border = [false,false,false,true]
      texto.borderColor = ['', '', '', '#F2F2F2']

      row1.push(texto);

      bodyGrillaCheckList.push(row1)
    }


    var bodyGrilla = [
        [ { text: '', border: [false, false, false, false] } ,
          { text: '', border: [false, false, false, false] } ,
          { text: '', border: [false, false, false, false] } ,
          { text: '', border: [false, false, false, false] } ,
        ],
    ]


    texto = {}
    for (var key in grilla) {
        var dataGrilla = grilla[key];
        var row = new Array();

        texto = {}
        texto.text = dataGrilla.us_Nombre
        texto.border = [false,false,false,true]
        texto.borderColor = ['', '', '', '#F2F2F2']
        texto.style = 'fieldData'
        row.push(texto);

        texto = {}
        texto.text = dataGrilla.uc_Nombre
        texto.border = [false,false,false,true]
        texto.borderColor = ['', '', '', '#F2F2F2']
        texto.style = 'fieldDataMin'
        row.push(texto);

        texto = {}
        // texto.text = dataGrilla.ua_Nombre
        texto.text = dataGrilla.ua_Abrev + ' | ' + dataGrilla.ua_Nombre
        texto.border = [false,false,false,true]
        texto.borderColor = ['', '', '', '#F2F2F2']
        texto.style = 'fieldDataMin'
        row.push(texto);

        texto = {}
        texto.text = dataGrilla.us_Mail
        texto.border = [false,false,false,true]
        texto.borderColor = ['', '', '', '#F2F2F2']
        texto.style = 'fieldDataMin'
        row.push(texto);

        bodyGrilla.push(row)
    }



  var dd = {
    pageSize: 'A4',
    pageMargins: [ 40, 30, 40, 30 ],
    info: {
        title: 'Proyecto | ' + data.codigoProyecto,
      },
    footer: function(currentPage, pageCount) {
      return [
        { columns: [
            { width: 'auto', text: 'SRK CONSULTING (ARGENTINA) S.A   |   ' + Intl.DateTimeFormat('es-AR', {year:'numeric', month:'2-digit', day:'2-digit'}).format(new Date()) , fontSize: 8, color: '#85929E', margin: [40, 0, 0, 0] },
            { text: currentPage.toString() + '/' + pageCount.toString(), fontSize: 8, color: '#85929E', alignment: 'right', margin: [0, 0, 40, 0]}
          ]},
        { canvas: [{ type: 'line', lineWidth: 1, x1: 40, y1: -15, x2: 595-40, y2: -15, lineColor: '#D26E3B' }] }
      ];
      },
    content: [
      {
        style: 'tableExample',
        margin: [0, 0, 0, 0],
        table: {
          widths:['*', 10 ,'auto'],
          body: [
            [ { image: 'logo',
                width: 1465/8, 
                height: 184/8,
                rowSpan: 2 },
                blanco,
                { },
            ],
            [
              { },
              blanco,
              { }
            ]
          ]
        },
        layout: 'noBorders',
      },

      {
        style: 'tableExample',
        margin: [0, 0, 0, 0],
        table: {
          widths:['*', 10 ,'auto'],
          body: [
            [ {},
                blanco,
                { },
            ],
            [
              { },
              blanco,
              { text: 'Form',  style: 'fieldHeader', alignment: 'right'}
            ],
            [
              { text: 'Alta de Proyectos',  style: 'propuestaNumero', alignment: 'left'},
              blanco,
              { text: '02',  style: 'propuestaNumero', alignment: 'right'}
            ]
          ]
        },
        layout: 'noBorders',
      },

    {
      style: 'tableExample',
      table: {
        widths:['*' , 10, 'auto'],
        body: [
          [ { text: 'Nombre', style: 'fieldHeader'},
            blanco,
            { text: 'Proyecto', style: 'fieldHeader'},
          ],
          [ { text: data.nombreProyecto, style: 'fieldData'},
             blanco,
            { text: data.codigoProyecto, style: 'propuestaNumero'},
          ]
        ]
      },
      layout: layoutCampo,
      },
      {
        style: 'tableExample',
        table: {
          widths:['*'],
          body: [
            [ { text: 'Descripción', style: 'fieldHeader'}
            ],
            [ { text: data.descripcionProyecto, style: 'fieldData'}
            ]
          ]
        },
        layout: layoutCampo,
        },
      {
        style: 'tableExample',
        margin: [0, 20, 0, 8],
        table: {
            widths: ['*'],
            headerRows: 0,
            body: [
                [ { text: 'Datos Generales', border: [false, false, false, true], fontSize: 18, color: '#D26E3B', borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#D26E3B']},
                ],
            ]
        }
      },
    {
        style: 'tableExample',
        table: {
          widths:[100, 10, 100, 10, 100, 10, '*'],
          body: [
            [   { text: 'Area', style: 'fieldHeader'},
                blanco,
                { text: 'Plazo | Meses', style: 'fieldHeader'},
                blanco,
                { text: 'Fecha Inicio', style: 'fieldHeader'},
                blanco,
                { text: 'Fecha Cierre Proyectado', style: 'fieldHeader'}
            ],
          [
            { text: data.areaNombre, style: 'fieldData'},
            blanco,
            { text: meses, style: 'fieldData'},
            blanco,
            { text: Intl.DateTimeFormat('es-AR', {year:'numeric', month:'2-digit', day:'2-digit'}).format(data.fechaInicio), style: 'fieldData'},
            blanco,
            { text: fechaFin, style: 'fieldData'}
          ]
          ]
        },
        layout: layoutCampo,
      },
      {
        style: 'tableExample',
        table: {
          widths:['49%', '2%', '49%'],
          body: [
            [   { text: 'Jefe de Proyecto', style: 'fieldHeader'},
                blanco,
                { text: 'Responsable Administrativo', style: 'fieldHeader'}
            ],
          [
            { text: data.prJefeProyectoNombre, style: 'fieldData', },
            blanco,
            { text: data.prResponsableAdministrativoNombre, style: 'fieldData' }
          ]
          ]
        },
        layout: layoutCampo,
    },
      {
        style: 'tableExample',
        table: {
          widths:['49%'],
          body: [
            [   { text: 'Revisor Técnico', style: 'fieldHeader'},
            ],
          [
            { text: data.prRevisorTecnicoNombre, style: 'fieldData', },
          ]
          ]
        },
        layout: layoutCampo,
    },
      {
        style: 'tableExample',
        margin: [0, 20, 0, 8],
        table: {
            widths: ['*'],
            headerRows: 0,
            body: [
                [ { text: 'Datos Económicos', border: [false, false, false, true], fontSize: 18, color: '#D26E3B', borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#D26E3B']},
                ],
            ]
        }
      },
      {
        style: 'tableExample',
        table: {
          widths:[100, 10, 100, 10, 100, 10, '*'],
          body: [
            [   { text: 'Tipo de Contrato',style: 'fieldHeader'},
                blanco,
                { text: 'Tipo de Moneda', style: 'fieldHeader'},
                blanco,
                { text: 'Honorarios Arg. (FEES.AR)', style: 'fieldHeader'},
                blanco,
                { text: 'Honorarios Otras CP (FEES.ICWA)', style: 'fieldHeader'},
            ],
          [
            { text: data.tipoContratoNombre, style: 'fieldDataMin'},
            blanco,
            { text: data.tipoMonedaNombre, style: 'fieldDataMin'},
            blanco,
            { text: formatImporte.format(data.importeHonorarios), style: 'fieldDataIzq'},
            blanco,
            { text: formatImporte.format(data.importeHonorariosOtras), style: 'fieldDataIzq'},
          ]
          ]
        },
        layout: layoutCampo
      },
      {
        style: 'tableExample',
        table: {
          widths:[100, 10, 100, 10, 100, 10, '*'],
          body: [
            [   { text: 'Gastos Previstos (DISB)',style: 'fieldHeader'},
                blanco,
                { text: 'Gastos Generales (OVHD)', style: 'fieldHeader'},
                blanco,
                { text: 'Impuestos Retención (WTHX)', style: 'fieldHeader'},
                blanco,
                { text: 'TOTAL', style: 'fieldHeader'},
            ],
          [
            { text: formatImporte.format(data.importeGastosPrevistos), style: 'fieldDataIzq'},
            blanco,
            { text: formatImporte.format(data.importeGastosGenerales), style: 'fieldDataIzq'},
            blanco,
            { text: formatImporte.format(data.importeImpuestos), style: 'fieldDataIzq'},
            blanco,
            { text: formatImporte.format(data.importeTotal), style: 'fieldDataIzqBold'},
          ]
          ]
        },
        layout: layoutCampo
      },
      {
        style: 'tableExample',
        margin: [0, 20, 0, 8],
        table: {
            widths: ['*'],
            headerRows: 0,
            body: [
                [ { text: 'CheckList', border: [false, false, false, true], fontSize: 18, color: '#D26E3B', borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#D26E3B']},
                ],
            ]
        }
      },
      {
        style: 'tableExample',
        table: {
            widths: ['*', 30],
            headerRows: 1,
            body: bodyGrillaCheckList
        },
        layout: 'lightHorizontalLines',
        pageBreak: 'after'
      },

      {
        style: 'tableExample',
        margin: [0, 20, 0, 8],
        table: {
            widths: ['*'],
            headerRows: 0,
            body: [
                [ { text: 'Datos de la Propuesta', border: [false, false, false, true], fontSize: 18, color: '#D26E3B', borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#D26E3B']},
                ],
            ]
        }
      },
    {
      style: 'tableExample',
      table: {
        widths:[300, 10, '*'],
        body: [
        [ { text: 'Cliente', style: 'fieldHeader'},
          blanco,
          { text: 'Categoria', style: 'fieldHeader'},
		],
        [
          { text: data.clienteNombre, style: 'fieldData'},
          blanco,
          { text: data.clienteCategoria, style: 'fieldData'}
        ]
        ]
      },
      layout: layoutCampo,
    },
    {
      style: 'tableExample',
      table: {
        widths:['*'],
        body: [
          [ { text:'Descripcion', style: 'fieldHeader'}],
          [ { text: data.descripcion, style: 'fieldData'}]
        ]
      },
      layout: layoutCampo,
    },
    {
      style: 'tableExample',
      table: {
        widths:[150, 10, 150, 10, '*'],
        body: [
          [   { text: 'Area', style: 'fieldHeader'},
              blanco,
              { text: 'Tipo Trabajo', style: 'fieldHeader'},
              blanco,
              { text: 'Tipo Trabajo | Otro', style: 'fieldHeader'},
          ],
        [
          { text: data.areaNombre, style: 'fieldData'},
          blanco,
          { text: data.tipoTrabajoNombre, style: 'fieldData'},
          blanco,
          { text: data.tipoTrabajoOtro, style: 'fieldData'},
        ]
        ]
      },
      layout: layoutCampo,
    },
    {
      style: 'tableExample',
      table: {
        widths:['49%', '2%', '49%'],
        body: [
          [   { text: 'Jefe de Proyecto', style: 'fieldHeader'},
              blanco,
              { text: 'Responsable Administrativo', style: 'fieldHeader'}
          ],
        [
          { text: data.jefeProyectoNombre, style: 'fieldData', },
          blanco,
          { text: data.responsableAdministrativoNombre, style: 'fieldData' }
        ]
        ]
      },
      layout: layoutCampo,
    },
    {
      style: 'tableExample',
      table: {
        widths:['*'],
        body: [
          [   
            { text: 'Ruta Carpeta', style: 'fieldHeader'}
          ],
          [
            { text: data.rutaCarpeta, style: 'fieldData'}
          ]
        ]
      },
      layout: layoutCampo,
    },
    {
      style: 'tableExample',
      margin: [0, 20, 0, 8],
      table: {
          widths: ['*'],
          headerRows: 0,
          body: [
              [ { text: 'Equipo de Trabajo', border: [false, false, false, true], fontSize: 18, color: '#D26E3B', borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#D26E3B']},
              ],
          ]
      }
    },
    {
      style: 'tableExample',
      table: {
          widths: [150, 100, 100, '*'],
          headerRows: 1,
          body: bodyGrilla
      },
      layout: 'lightHorizontalLines'
    },
  ],
  images: {
    logo: 'https://s3.sa-east-1.amazonaws.com/srk.varios/LogoSRKConsulting.jpg'
  },
  styles: {
    tableExample: {
      margin: [0, 5, 0, 5]
    },
    fieldHeader: {
      color: 'grey',
      fontSize: 9
    },
    fieldData: {
      fontSize: 10
    },
    fieldDataMin: {
      fontSize: 8
    },
    fieldDataIzq: {
      fontSize: 10,
      alignment: 'right'
    },
    fieldDataIzqBold: {
      fontSize: 10,
      alignment: 'right',
      bold: true
    },
    propuestaNumero: {
      fontSize: 18,
      bold: true    }
  }

  }

    return dd
}

