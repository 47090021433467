
<template>
  <div>

    <div class="dx-card responsive-paddings m-4">

      <form
        target="/proyectos-tabla"
        @submit="handleSubmit"
        id="formulario"
      >
      <DxForm
        ref="myForm"
        label-location="top"
        label-mode="floating"
        :form-data="newProject"
        @field-data-changed="onFieldDataChanged"
        :show-validation-summary="true"
        @content-ready="onContentReady"
        :show-colon-after-label="false"
      >

        <DxGroupItem
            caption="Datos Propuesta"
            :col-count="6">

          <DxSimpleItem 
            data-field="propuestaId"
            :col-span="5"
            editor-type="dxSelectBox"
            :editor-options="propuestaEditorOptions"
          >
            <DxLabel
              text="Propuesta"
            />
            <DxRequiredRule message="Indicar la Propuesta es Obligatorio"/>
          </DxSimpleItem>

          <DxItem
            template="buttonVerPropuesta"
          >
          </DxItem>


        </DxGroupItem>
        <template #buttonVerPropuesta>
            <DxButton 
              @click="onButtonVerPropuestaClick"
              styling-mode="outlined"
              :element-attr="{ class: 'btn-toolbar'}"
            >
            <span>
              {{ btnVerPropuestaText }}
            </span>
            </DxButton>
        </template>

        <DxGroupItem
          :col-count="6"
          css-class="datospropuesta"
          :visible="verPropuesta"  
        >
        <DxSimpleItem 
            data-field="nombre"
            :col-span="5" 
            :editor-options="{ readOnly: true}"
          >
            <DxLabel text="Nombre de la Propuesta"/>
          </DxSimpleItem>

        <DxSimpleItem
            data-field="fechaPropuesta"
            :col-span="1"
            editor-type="dxDateBox"
            :editor-options="{ readOnly: true, displayFormat:'dd/MM/yyyy' }"
          >
            <DxLabel text="Fecha"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="descripcion"
            :col-span="6"
            :editor-options="{ readOnly: true}"
          >
            <DxLabel text="Descripcion de la Propuesta"/>
          </DxSimpleItem>

        <DxSimpleItem 
            data-field="jefeProyectoNombre"
            :col-span="3"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Jefe de Proyecto"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="responsableAdministrativoNombre"
            :col-span="3"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Responsable Administrativo"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="clienteNombre"
            :col-span="4"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Cliente"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="clienteCategoria"
            :col-span="2"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Categoría"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="areaNombre"
            :col-span="1"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Area"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="tipoTrabajoNombre"
            :col-span="1"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Tipo de Trabajo"/>
          </DxSimpleItem>
          <DxSimpleItem 
            data-field="tipoTrabajoOtro"
            :col-span="2"
            :editor-options="{ readOnly: true }"
          >
            <DxLabel text="Tipo de Trabajo | Otro"/>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="horasPropuesta"
            :col-span="2"
          >
            <DxLabel text="Horas Propuesta"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="rutaCarpeta"
            :col-span="6"
            :editor-options="{ readOnly: true}"
          >
            <DxLabel text="Ruta Carpeta"/>
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem
          caption="Datos Proyecto"
          :col-count="12"
        >
          <DxSimpleItem
            data-field="codigoProyecto"
            :col-span="3"
            editor-type="dxTextBox"
            :editor-options="{ readOnly: true}"
          >
            <DxLabel text="Código del Proyecto"/>
            <DxRequiredRule
              message="Código del Proyecto Obligatorio"
            />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="fechaProyecto"
            :col-span="3"
            editor-type="dxDateBox"
            :editor-options="{ displayFormat:'dd/MM/yyyy' }"
          >
            <DxLabel text="Fecha"/>
          </DxSimpleItem>

          <DxEmptyItem 
            :col-span="6" />

          <DxSimpleItem
            data-field="nombreProyecto"
            :col-span="12"
            editor-type="dxTextBox"
          >
            <DxLabel text="Nombre del Proyecto"/>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="descripcionProyecto"
            :col-span="12"
            editor-type="dxTextBox"
          >
            <DxLabel text="Descripción del Proyecto"/>
          </DxSimpleItem>


          <DxSimpleItem 
            data-field="areaId"
            :col-span="3"
            editor-type="dxSelectBox"
            :editor-options="areaIdEditorOptions"
          >
            <DxLabel
              text="Area"
            >
            </DxLabel>
            <DxRequiredRule
              message="Area Obligatoria"
            />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="fechaInicio"
            :col-span="3"
            editor-type="dxDateBox"
            :editor-options="fechainicioEditorOptions"
          >
            <DxLabel text="Fecha Inicio"/>
            <DxRequiredRule
              message="Fecha de Inicio Obligatoria"
            />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="fechaFin"
            template="templatefechaFin"
            :col-span="3"
          >
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="meses"
            :col-span="1"
            :editor-options="{ readOnly: true, width: 60}"
          >
            <DxLabel text="Meses"/>
          </DxSimpleItem>

          <DxSimpleItem 
            data-field="hhProyectadas"
            :col-span="2"
          >
            <DxLabel text="Hs Proyectadas"/>
            <DxRequiredRule
              message="Horas Proyectadas Obligatorias"
            />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="prJefeProyectoId"
            :col-span="4"
            editor-type="dxSelectBox"
            :editor-options="jefeProyectoEditorOptions"
          >
          <DxLabel text="Jefe de Proyecto"/>
            <DxRequiredRule
              message="Jefe de Proyecto Obligatorio"
            />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="prResponsableAdministrativoId"
            :col-span="4"
            editor-type="dxSelectBox"
            :editor-options="responsableAdministrativoEditorOptions"
          >
          <DxLabel text="Responsable Administrativo"/>
            <DxRequiredRule
              message="Responsable Administrativo Obligatorio"
            />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="prRevisorTecnicoId"
            :col-span="4"
            editor-type="dxSelectBox"
            :editor-options="revisorTecnicoEditorOptions"
          >
          <DxLabel text="Revisor Técnico"/>
            <DxRequiredRule
              message="Revisor Técnico Obligatorio"
            />
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem
          caption="Datos Económicos"
          :col-count="15"
          name="datosEconomicos">

          <DxSimpleItem 
            ref="sbTipoContrato"
            data-field="tipoContratoId"
            :col-span="3"
            editor-type="dxSelectBox"
            :editor-options="tipoContratoEditorOptions"
          >
            <DxLabel
              text="Tipo de Contrato"
            />
            <DxRequiredRule message="Indicar el Tipo de Contrato es Obligatorio"/>
          </DxSimpleItem>


          <DxSimpleItem 
            data-field="tipoMonedaId"
            :col-span="3"
            editor-type="dxSelectBox"
            :editor-options="tipoMonedaEditorOptions"
          >
            <DxLabel
              text="Tipo de Moneda"
            />
            <DxRequiredRule message="Indicar el Tipo de Moneda es Obligatorio"/>
          </DxSimpleItem>

          <DxSimpleItem
            data-field="importeHonorarios"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: 'Honorarios Arg (FEES.AR)'}"
            :editor-options="{ format: { type: 'currency', precision: 0, currency: tipoCurrency },
                               showClearButton: false,
                               step: 0 }"
   
            >

          </DxSimpleItem>

          <DxSimpleItem
            data-field="cotizacionDolar"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: lblCotizacion}"
            :visible="newProject.tipoMonedaId == 0"
            :editor-options="{ format: { type: 'currency', precision: 2, currency: 'ARS' },
                               showClearButton: false,
                               step: 0,
                               readOnly: true }"
          />
          <DxEmptyItem
            :col-span="3"
            :visible="newProject.tipoMonedaId != 0"
          />

          <DxSimpleItem
            data-field="importeHonorariosDolar"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: 'Honorarios Arg en USD'}"
            :visible="newProject.tipoMonedaId == 0"
            :editor-options="{ format: { type: 'currency', precision: 2, currency: 'USD' },
                               showClearButton: false,
                               step: 0,
                               readOnly: true }"
          />
          <DxEmptyItem
            :col-span="3"
            :visible="newProject.tipoMonedaId != 0"
          />


          <DxSimpleItem
            data-field="importeHonorariosOtras"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: 'Honorarios otras CP (FEES.ICWA)'}"
            :editor-options="{ format: { type: 'currency', precision: 0, currency: tipoCurrency },
                               showClearButton: false ,
                               step: 0 }"

          >
          </DxSimpleItem>

          <DxSimpleItem
            data-field="importeGastosPrevistos"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: 'Gastos previstos (DISB)'}"
            :editor-options="{ format: { type: 'currency', precision: 0, currency: tipoCurrency },
                               showClearButton: false ,
                               step: 0 }"
          >

          </DxSimpleItem>

          <DxSimpleItem
            data-field="importeGastosGenerales"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: 'Gastos generales (OVHD)'}"
            :editor-options="{ format: { type: 'currency', precision: 0, currency: tipoCurrency },
                               showClearButton: false ,
                               step: 0 }"
          >

          </DxSimpleItem>

          <DxSimpleItem
            data-field="importeImpuestos"
            :col-span="3"
            data-type="number"
            editor-type="dxNumberBox"
            :label="{ text: 'Impuesto de Retencion (WTHX)'}"
            :editor-options="{ format: { type: 'currency', precision: 0, currency: tipoCurrency },
                               showClearButton: false ,
                               step: 0 }"
          >

          </DxSimpleItem>

          <DxSimpleItem
            data-field="importeTotal"
            template="total"
            :col-span="3"
          >
          </DxSimpleItem>

          </DxGroupItem>

        <template #templatefechaFin>
          <DxDateBox
            label="Fecha Cierre"
            :value="newProject.fechaFin"
            :min="minimaFechaFin"
            displayFormat="dd/MM/yyyy"
            @value-changed="function(e) {
              handleValueChanged(e.value, 'fechaFin')
              handleValueChanged(monthDiff(newProject.fechaInicio, newProject.fechaFin),'meses')}"
          >

          </DxDateBox>
        </template>

        <template #grilla>
          <DxDataGrid 
            ref="myGrilla"
            :data-source="checkList"
            :show-borders="true"
            :column-auto-width="true"
            :word-wrap-enabled="true"
            :show-row-lines="true"
          >
            <DxColumn
              data-field="text"
              caption="Item"
            >
            </DxColumn>
            <DxColumn
              data-field="value"
              data-type="boolean"
              caption="Respuesta"
              width="100px"
            >
            </DxColumn>
            <DxEditing
              :allow-updating="true"
              :use-icons="true"
              refresh-mode="repaint"
              mode="cell">
            </DxEditing>
          </DxDataGrid>
        </template>

        <template #total>

          <div class="dx-field-label">
            TOTAL
          </div>
          <div class="dx-field-value" id="total">
            <DxNumberBox
              :read-only="true"
              :value="newProject.importeTotal"
              :format=" { type: 'currency', precision: 0, currency: tipoCurrency }"
            >

            </DxNumberBox> 
          </div>

        </template>

          <DxSimpleItem
            data-field="equipoTrabajo"
            :col-span="6"
          >
            <DxTagBox
              :data-source="dataUsuarios"
              label="Equipo de Trabajo"
              display-expr="us_Nombre"
              value-expr="us_Id"
              :value=newProject.equipoTrabajo
              :search-enabled="true"
              @value-changed="function (e) {handleValueChanged(e.value,'equipoTrabajo')}"
            />
            <DxRequiredRule
              message="Equipo de Trabajo Obligatorio"
            />
          </DxSimpleItem>
             
          <DxGroupItem
            caption="Informacion Adicional"
            :col-count="4"
          >

          <DxTabbedItem
              :col-span="4"
          >   

            <!-- <DxTab
              :col-count="1"
              title="Equipo de Trabajo por Tareas"
            > 
              <DxSimpleItem
                template="grillaEquipo"
              />
            </DxTab> -->

            <DxTab
              :col-count="1"
              title="Check-List"
            >
              <DxSimpleItem
                template="grilla"
              >
              </DxSimpleItem>
            </DxTab>


          </DxTabbedItem>
        </DxGroupItem>

        <template #grillaEquipo> 
          <div class="datagrid">
            <DxDataGrid
              id="gridContainer"
              ref="myDataEquipo"
              :data-source="dataTareas"
              @row-updating="updateTareaRow"
              @init-new-row="onNewTareaRow"
            >
              <DxToolbar
                :visible="false"
              />
              <DxColumn 
                caption="Tareas / Documento"
                data-field="pt_Tarea"
                data-type="string"
                width="800"
              >
              </DxColumn>
              <DxColumn 
                caption="Total Horas"
                data-field="sumaHoras"
                data-type="number"
                :min-width=50
              >
              </DxColumn>
              <DxColumn 
                caption="Total Fees"
                data-field="sumaTarifas"
                data-type="number"
                :min-width=50
              >
              </DxColumn>
              <DxColumn type="buttons">
                <DxButton
                icon="add"
                @click="onAddButtonClick"
                />
                <DxButton name="edit"/>
                <DxButton name="delete"/>
                <DxButton name="save"/>
                <DxButton name="cancel"/>
              </DxColumn>
              <DxMasterDetail
                :enabled="true"
                template="masterDetailTemplate"
              />
                <template #masterDetailTemplate="{ data: data}">
                  <ExtDetailTemplate
                    :template-data="data"
                    :gridinstance="myDataEquipo"
                  />
                </template>

              <DxEditing
                :allow-updating="true"
                :allow-deleting="true"
                :use-icons="true"
                mode="cell"
              >
              </DxEditing>
            </DxDataGrid>
          </div>
        </template>

        <DxValidationSummary />

        <DxGroupItem
          :col-count="3"
         >
          <DxEmptyItem>

          </DxEmptyItem>

          <DxItem
            :button-options="boPDF"
            item-type="button"
          >
          </DxItem>
          <DxItem
            :button-options="buttonOptions"
            item-type="button"
          >
          </DxItem>
        </DxGroupItem>

    </DxForm>



    </form>
    </div>
  </div>

  <div id="popup-container">
    <DxPopup            
      v-model:visible="isPopupVisible"
      :hide-on-outside-click="true"
      :show-close-button="true"
      @hiding="onHiding"
      height="250"
      width="400"
      :title="popupTitle"
      >
      <p  class="text-center">
        {{ popupTexto }}
      </p>
      <h3  class="text-center">
        {{  newProject.codigoProyecto }}
      </h3>
    </DxPopup>
  </div>

  <DxLoadPanel
      :position="{ of: '#formulario' }"
      v-model:visible="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"
    />

</template>

<script setup>
import {
  DxForm, DxSimpleItem, DxItem, DxGroupItem, DxRequiredRule, DxLabel,  DxEmptyItem, 
  DxTabbedItem, DxTab,
} from 'devextreme-vue/form'

import { DxDataGrid, DxColumn, DxToolbar, DxEditing, DxMasterDetail } from 'devextreme-vue/data-grid'
import ExtDetailTemplate from './proyecto-nuevo-detailEquipo.vue'

import { ref, onMounted } from 'vue'

import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import notify from 'devextreme/ui/notify'

import { DxPopup } from 'devextreme-vue/popup'

import DxValidationSummary from "devextreme-vue/validation-summary"

import {useRoute} from 'vue-router'
import router from '../router'

import DxTagBox from 'devextreme-vue/tag-box'
import DxDateBox from 'devextreme-vue/date-box'
import { DxButton } from 'devextreme-vue/button'

import { DxNumberBox } from 'devextreme-vue/number-box'
// import { DxTextArea } from 'devextreme-vue/text-area'

import { DxLoadPanel } from 'devextreme-vue/load-panel'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import store from '../store/index.js'
import moment from 'moment'

import myPDF from '../pdf/proyecto-pdf.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const myDataEquipo = ref(null)

const pdf64 = ref(null) 

const route = useRoute()
const projectId = route.params.project !== '' ? route.params.project : null

const popupTitle = ref(null)
const popupTexto = ref(null)

const updateTareas = ref(null)
const selectedItemKeys = ref(null)

const verPropuesta = ref(false)

const titulo =ref('Nuevo Proyecto')

const sbTipoContrato = ref(null)

const tipoCurrency = ref(null)

const btnVerPropuestaText = ref('Ver Propuesta')

const loadingVisible = ref(false)

const lblCotizacion = ref('Cotización Dolar')

const cbTipoMoneda = new DataSource({
  store: {
    type: "array",
    key: "value",
    data: [
      { value: 0, text:'Pesos Argentinos (ARS)', intlcurrency: 'ARS'},
      { value: 1, text:'Dolares Estadounidenses (USD)', intlcurrency: 'USD'}
    ]
  }
})

const cbTipoContrato = new DataSource({
  store: {
    type: "array",
    key: "value",
    data: [
      { value: 0, text:'Ajuste Alzado'},
      { value: 1, text:'Unidad de Medida'}
    ]
  }
})

const checkList = [
  {'field': 'siReembolsables','text': 'Gastos Reembolsables por Cliente', 'value': 0},
  {'field': 'siHorasExtras','text': 'Se imputarán Horas Extras por trabajo en terreno', 'value': 0},
  {'field': 'siICWAfirmado','text': 'Requiere ICWA firmado', 'value': 0}
]

const newProject = ref({
  propuestaId: null,
  codigoPropuesta: null,
  fechaPropuesta: null,
  clienteId: null,
  clienteNombre: null,
  clienteAbrev: null,
  clienteCategoria: null,
  clienteCategoriaId: null,
  nombre: '',
  descripcion: '',
  areaId: null,
  areaNombre: null,
  tipoTrabajoId: null,
  tipoTrabajoNombre: null,
  tipoTrabajoOtro: '',

  jefeProyectoId: null,
  jefeProyectoNombre: null,
  responsableAdministrativoId: null,
  responsableAdministrativoNombre: null,
  
  rutaCarpeta: null,
  codigoProyecto: null,
  nombreProyecto: null,
  descripcionProyecto: null,

  fechaProyecto: new Date(),
  fechaInicio: new Date(),
  fechaFin: new Date(),
  meses: 0,
  hhProyectadas: null,

  tipoContratoId: null,
  tipoMonedaId: null,
  tipoContratoNombre: null,
  tipoMonedaNombre: null,

  importeHonorarios: 0,
  importeHonorariosOtras: 0,
  importeGastosPrevistos: 0,
  importeGastosGenerales: 0,
  importeImpuestos: 0,
  importeTotal: 0,

  cotizacionDolar: 1,
  importeHonorariosDolar: 0,

  siReembolsables: null,
  siHorasExtras: null,
  siICWAfirmado: null,

  prJefeProyectoId: null,
  prJefeProyectoNombre: null,
  prResponsableAdministrativoId: null,
  prResponsableAdministrativoNombre: null,
  prRevisorTecnicoId: null,
  prRevisorTecnicoNombre: null,

  equipoTrabajo: [],
  equipo: null,
  id: null,

  horasPropuesta: '',
  vevPropuesta: false

}
)
var minimaFechaFin = ref(newProject.value.fechaInicio)
const isPopupVisible = ref(false)

const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'


function monthDiff(d1, d2) { 
  var desde = moment(d1)
  var hasta = moment(d2)
  return hasta.diff(desde, 'months') + 1
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


const dataUsuarios = new DataSource({
  paginate: false,
  store: new CustomStore({
      key: 'us_Id',
      loadMode: 'raw',
      load: () => {
        let url = baseUrl + 'user/0'
        return fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .catch(() => { throw 'Problema en la Conexión de Red' + url})
      }
  })
})

const cbPropuestas = new DataSource({
  store: new CustomStore({
      loadMode: 'raw',
      key: 'pp_Id',
      byKey: async (keyval) => {
        let url = baseUrl + 'propuesta/' + keyval
        return await fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .catch(() => { throw 'Problema en la Conexión de Red' + url})
      },
      load: async () => {
        let url = baseUrl
        if (projectId == null)
          url = url + 'propuesta/0?tt=1'
        else
          url = url + 'propuesta/0'
        return await fetch(url)
        .then(handleErrors)
        .then(response => response.json())
        .catch(() => { throw 'Problema en la Conexión de Red' + url})
      }
  }),

})

const tipoArea = new DataSource({
    store: new CustomStore({
        key: 'ua_Id',
        loadMode: 'raw',
        load: () => {
          let url = baseUrl + 'userarea/0'
          return fetch(url)
          .then(handleErrors)
          .then(response => response.json())
          .catch(() => { throw 'Problema en la Conexión de Red' + url})
        }
    })
})

const dataTareas = new DataSource({
  store: new CustomStore({
  key: 'pt_Id',
  load: () => {
    let url = baseUrl + 'proyectoTarea/308'
    return fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .catch(() => { throw 'Problema en la Conexión de Red'})
  },
    update: (key) => {
      var newBody = {
          tarea: updateTareas.value.pt_Tarea,
          id: key
          }
      return fetch(baseUrl + "proyectoTarea", {
          method: 'POST',
          body: JSON.stringify(newBody)
      })
      .then(handleErrors)
    },
    remove: (key) => {
        return fetch( baseUrl + "proyectoTarea?id="+key, {
            method: "DELETE"
        })
        .then(handleErrors)
    },
    onLoaded: (data) => {
      console.log(data)
      newProject.value.importeHonorarios = data[0].sumaTarifas
    }
  })
})


const myForm = ref(null)

const propuestaEditorOptions = {
  dataSource: cbPropuestas,
  searchEnabled: true,
  readOnly: projectId !== null,
  value: newProject.value.propuestaId,
  displayExpr: function(item) {
    return item && item.pp_Codigo + ' | ' + item.pp_Nombre
  },
  valueExpr: "pp_Id",
  onValueChanged: function(e) {
    cargaPropuesta(e.value)
    armaNombreProyecto()
    handleValueChanged(e.value,'propuestaId')
  }
}

const tipoContratoEditorOptions = {
  dataSource: cbTipoContrato,
  searchEnabled: true,
  value: newProject.value.tipoContratoId,
  displayExpr: 'text',
  valueExpr: 'value',
  onValueChanged: function(e) {
    var index = cbTipoContrato._items.findIndex(function(item) {
        return item.value === e.value
    }) 
    if (index >= 0) {
      newProject.value.tipoContratoNombre = cbTipoContrato._items[index].text
      handleValueChanged(e.value,'tipoContratoId')
    }
  }
}

const tipoMonedaEditorOptions = {
  dataSource: cbTipoMoneda,
  searchEnabled: true,
  value: newProject.value.tipoMonedaId,
  displayExpr: 'text',
  valueExpr: 'value',
  onValueChanged: function(e) {
    var index = cbTipoMoneda._items.findIndex(function(item) {
        return item.value === e.value
    }) 
    if (index >= 0) {
      newProject.value.tipoMonedaNombre = cbTipoMoneda._items[index].text
      tipoCurrency.value = cbTipoMoneda._items[index].intlcurrency
      handleValueChanged(e.value,'tipoMonedaId')
    }
  }
}

const areaIdEditorOptions = {
  readOnly: projectId !== null,
  searchEnabled: true,
  dataSource: tipoArea,
  value: newProject.value.areaId,
  displayExpr: "ua_AbrevNombre",
  valueExpr: "ua_Id",
  onValueChanged: function(e) {    
    var index = tipoArea._items.findIndex(function(item) {
        return item.value === e.value
    }) 
    if (index >= 0) {
      newProject.value.areaNombre = tipoArea._items[index].ua_AbrevNombre 
      handleValueChanged(e.value,'areaId') 
    }
  }
}
const jefeProyectoEditorOptions = {
  searchEnabled: true,
  dataSource: dataUsuarios,
  value: newProject.value.prJefeProyectoId,
  displayExpr: "us_Nombre",
  valueExpr: "us_Id",
  onValueChanged: function(e) {
    var index = dataUsuarios._items.findIndex(function(item) {
        return item.us_Id === e.value
    }) 
    if (index >= 0) {
      newProject.value.prJefeProyectoNombre = dataUsuarios._items[index].us_Nombre
      handleValueChanged(e.value,'prJefeProyectoId')
    }
  }
}

const responsableAdministrativoEditorOptions = {
  searchEnabled: true,
  dataSource: dataUsuarios,
  value: newProject.value.prResponsableAdministrativoId,
  displayExpr: "us_Nombre",
  valueExpr: "us_Id",
  onValueChanged: function(e) {
    var index = dataUsuarios._items.findIndex(function(item) {
        return item.us_Id === e.value
    }) 
    if (index >= 0) {
      newProject.value.prResponsableAdministrativoNombre = dataUsuarios._items[index].us_Nombre
      handleValueChanged(e.value,'prResponsableAdministrativoId')
    }
  }
}

const revisorTecnicoEditorOptions = {
  searchEnabled: true,
  dataSource: dataUsuarios,
  value: newProject.value.prRevisorTecnicoId,
  displayExpr: "us_Nombre",
  valueExpr: "us_Id",
  onValueChanged: function(e) {
    var index = dataUsuarios._items.findIndex(function(item) {
        return item.us_Id === e.value
    }) 
    if (index >= 0) {
      newProject.value.prRevisorTecnicoNombre = dataUsuarios._items[index].us_Nombre
      handleValueChanged(e.value,'prRevisorTecnicoId')
    }
  }
}

const fechainicioEditorOptions = {
  displayFormat: "dd/MM/yyyy",
  onValueChanged: function(e) {
    minimaFechaFin.value = e.value
  }
}


function onButtonVerPropuestaClick() {
  verPropuesta.value = !verPropuesta.value
  btnVerPropuestaText.value = verPropuesta.value ? 'Ocultar Propuesta' : 'Ver Propuesta'
}

const buttonOptions = {
  disabled: true,
  text: projectId == null ? 'Grabar Proyecto' : 'Modificar Proyecto',
  type: 'success',
  useSubmitBehavior: true
}


function envioMail(codigo) {

  let equipo = []
  if (newProject.value.equipoTrabajo.length > 0)
    newProject.value.equipoTrabajo.forEach(element => {
      equipo.push(dataUsuarios._items.find(x => x.us_Id === element))
    });

  const pdfDocGenerator = pdfMake.createPdf(myPDF(newProject.value, equipo, checkList))
  pdfDocGenerator.getBase64( function(data) { 
  pdf64.value = data
  fetch('https://phrd8s6rk2.execute-api.sa-east-1.amazonaws.com/sendmail', {
  mode: 'no-cors',
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    senderName: 'SRKapp Proyectos <projectcontrolar@srk.com.ar>',
    senderEmail: 'projectcontrolsrk@gmail.com',
    message: 'Se adjunta Formulario correspondiente al Proyecto ' + codigo ,
    base64Data: pdf64.value, 
    asunto: popupTitle.value + ' | ' + codigo,
    filename: codigo + '.pdf'
    })
  })
})
}

function handleSubmit(e) {

    let validationResult = myForm.value.instance.validate()
    if (validationResult.isValid) {
      let url = baseUrl + "project"
      let metodo

      checkList.forEach( item => {
        newProject.value[item.field] = item.value
      })
      newProject.value.fechaInicio = new Date(newProject.value.fechaInicio.toISOString().split('T')[0])
      if (newProject.value.fechaFin)
        newProject.value.fechaFin = new Date(newProject.value.fechaFin.toISOString().split('T')[0])
      newProject.value.fechaProyecto = new Date(newProject.value.fechaProyecto.toISOString().split('T')[0])

      if (projectId == null ) {
        popupTitle.value = ' Nuevo Proyecto'
        popupTexto.value = 'Se ha generado el Proyecto'
        metodo = 'PUT'
        }
      else {
        popupTitle.value = ' Modificacion Proyecto'
        popupTexto.value = 'Se ha modificado el Proyecto'
        metodo = 'POST'
      }
      let cuerpo = {
        prop: newProject.value.propuestaId,
        proj: null
      }  


      fetch(url, {
        method: metodo,
        body: JSON.stringify(newProject.value)
        })
        .then(handleErrors)
        .then(resp => resp.json())
        .then( (put_data) => {
          cuerpo.proj =  put_data.insertId
          if (metodo == 'PUT') {
            let url = baseUrl + 'prop2proj' 
            return fetch(url, {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify(cuerpo) 
            })
            .then(handleErrors)
            .catch( () => { throw 'Problema en la Conexión de Red' + url } )
          }
        })
        .catch(() => { throw 'Problema en la Conexión de Red' + url })

      e.preventDefault()
      isPopupVisible.value = true
      envioMail(newProject.value.codigoProyecto)
      myForm.value.instance.resetValues()
      // newProject.value.codigoProyecto = null
      // cbPropuestas.load()
      // router.push('/proyecto-nuevo')

    }
  else
    notify('Hay Errores - Revise', 'error', 3000)
  }



function makePDF() {

  let equipo = []
  if (newProject.value.equipoTrabajo.length > 0)
    newProject.value.equipoTrabajo.forEach(element => {
      equipo.push(dataUsuarios._items.find(x => x.us_Id === element))
    });
  pdfMake.createPdf(myPDF(newProject.value, equipo, checkList)).open()
}


const boPDF = {
  text: 'Ver Documento',
  onClick: function () { makePDF() }
}
    
function onHiding() {
  if (projectId !== null)
    router.push('/proyecto-tabla')
}

function actualizaCombo() {
  if (newProject.value.propuestaId !== null)
    myForm.value.instance.updateData('propuestaId',newProject.value.propuestaId)
    myForm.value.instance.updateData('areaId',newProject.value.areaId)
    myForm.value.instance.updateData('revisorAdministrativoId',newProject.value.revisorAdministrativoId)
    myForm.value.instance.updateData('prJefeProyectoId',newProject.value.prJefeProyectoId)
    myForm.value.instance.updateData('prResponsableAdministrativoId',newProject.value.prResponsableAdministrativoId)
    myForm.value.instance.updateData('prRevisorTecnicoId',newProject.value.prRevisorTecnicoId)
    myForm.value.instance.updateData('tipoContratoId',newProject.value.tipoContratoId)
    myForm.value.instance.updateData('tipoMonedaId',newProject.value.tipoMonedaId)
  }

function cotizacion() {
  let baseUrlCBCRA = 'https://api.bcra.gob.ar/estadisticas/v2.0/datosvariable/4/'
  if (newProject.value.importeHonorarios > 0) {
    let desde = moment(newProject.value.fechaInicio).add(-7,'d').format('YYYY-MM-DD')
    let hasta = moment(newProject.value.fechaInicio).add(-1,'d').format('YYYY-MM-DD')
    baseUrlCBCRA = baseUrlCBCRA + desde + '/' + hasta
    fetch(baseUrlCBCRA)
    .then(resp => resp.json())
    .then(data => {
      let cotFecha = data.results[data.results.length-1].fecha.substring(8,10) + '/' + data.results[data.results.length-1].fecha.substring(5,7)
      lblCotizacion.value = 'Cotización Dolar ' + cotFecha
      let cot = data.results[data.results.length-1].valor
      newProject.value.cotizacionDolar = cot
      newProject.value.importeHonorariosDolar = newProject.value.importeHonorarios / cot
      })
    .catch(e => console.log(e.error))
  }
}


function onFieldDataChanged(e) {
  if (e.dataField.substring(0,7) == 'importe') {
    let impTot = newProject.value.importeHonorarios + 
                  newProject.value.importeHonorariosOtras +
                  newProject.value.importeGastosGenerales +
                  newProject.value.importeGastosPrevistos +
                  newProject.value.importeImpuestos
    myForm.value.instance.updateData('importeTotal', impTot)
    }
  if (e.dataField == 'tipoMonedaId' && e.value == 0)
    cotizacion()
  if (e.dataField == 'fechaInicio' && newProject.value.tipoMonedaId == 0 && newProject.value.importeHonorarios > 0)
    cotizacion()
  if (e.dataField == 'importeHonorarios' && newProject.value.tipoMonedaId == 0 )
    cotizacion()
}

function handleValueChanged(value,key) {
  if (key == 'equipoTrabajo') {
    let tira = ''
    value.forEach(numero => tira = tira + (numero+10000).toString().substring(1,5) )
    newProject.value.equipo = tira
  }
  newProject.value[key] = value
}

function onContentReady() {
  actualizaCombo()
}


function sumaProject(valor) {
  var toDec = parseInt(valor,36) + 1
  return toDec.toString(36).toUpperCase()
}

function armaNombreProyecto() {
  if (projectId == null) {
    var nombre = newProject.value.areaNombre.substring(0,1) 
    let proximo = ''
    let url
    if (newProject.value.clienteCategoriaId == 6)  { // Es una SRK
      // Traigo ultimo Proyecto de esa SRK
      url = baseUrl + 'getultproject/' + newProject.value.clienteAbrev.substring(2,4)
      fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .then(data => {
        proximo = sumaProject(data[0].ultimo)
        nombre = nombre + newProject.value.clienteAbrev.substring(2,4) + proximo
        handleValueChanged(nombre,'codigoProyecto')  
      })
      .catch( () => { throw 'Problema en la Conexión de Red' + url} )
    }
    else {
      url = baseUrl + 'getultproject/A'
      fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .then(data => {
        proximo = sumaProject(data[0].ultimo)
        nombre = nombre + 'A' + proximo + '0'
        handleValueChanged(nombre,'codigoProyecto')  
      })
      .catch( () => { throw 'Problema en la Conexión de Red' + url} )
      }
    }
  else
    handleValueChanged(newProject.value.codigoProyecto,'codigoProyecto')

}


function cargaPropuesta(id) {
  var index = cbPropuestas._items.findIndex(function(item) {
    return item.pp_Id === id
  })


  if (index >= 0) {
  const data = cbPropuestas._items[index]

  newProject.value.propuestaId = data.pp_Id
  newProject.value.codigoPropuesta = data.pp_Codigo
  newProject.value.clienteId = data.pp_ClienteId
  newProject.value.clienteNombre = data.cl_Nombre
  newProject.value.clienteAbrev = data.cl_Abrev
  newProject.value.clienteCategoriaId = data.cl_CategoriaId
  newProject.value.clienteCategoria = data.Categoria
  newProject.value.nombre = data.pp_Nombre
  newProject.value.descripcion = data.pp_Descripcion

  newProject.value.areaId = data.pp_AreaId
  newProject.value.areaNombre = data.ua_Abrev + ' | ' + data.ua_Nombre
  newProject.value.tipoTrabajoId = data.pp_TipoTrabajoId
  newProject.value.tipoTrabajoNombre = data.tt_Nombre
  newProject.value.tipoTrabajoOtro = data.pp_TipoTrabajoOtro
  newProject.value.jefeProyectoId = data.pp_JefeProyectoId
  newProject.value.jefeProyectoNombre =  data.us_jp_Nombre
  newProject.value.responsableAdministrativoId = data.pp_ResponsableAdministrativoId
  newProject.value.responsableAdministrativoNombre = data.us_ad_Nombre

  if (projectId == null) {
    newProject.value.nombreProyecto = data.pp_Nombre
    newProject.value.descripcionProyecto = data.pp_Descripcion
    newProject.value.prJefeProyectoId = data.pp_JefeProyectoId
    newProject.value.prResponsableAdministrativoId = data.pp_ResponsableAdministrativoId
  }
  newProject.value.rutaCarpeta = data.pp_RutaCarpeta
  newProject.value.fechaPropuesta = new Date(data.pp_Fecha.toString().slice(0, 23))

  let url = baseUrl + 'getHoras?project=' + id + 
                      '&tipo=p&tarea=1'
  fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .then(data => {
      newProject.value.horasPropuesta = data[0].horas + ':' + data[0].minutos
    })
    .catch(() => { throw 'Problema en la Conexión de Red' + url})
}
}


onMounted( async () => {

  if (store.role == '') {
    await fetch(baseUrl + 'user/' + store.userId )
    .then(handleErrors)
    .then(response => response.json())
    .then(data => {
      if (data[0].us_IsAdmin)
        store.userSetRole('admin')
      else
        store.userSetRole('none')
    })
  }

  if (projectId !== null) {
    loadingVisible.value = true
    await tipoArea.load()
    await dataUsuarios.load()
    await cbPropuestas.load('carga')
    cbTipoContrato.load()
    cbTipoMoneda.load()
    let url = baseUrl + 'project/' + projectId
    fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .then(async data => {
      newProject.value = {
        propuestaId: data[0].pp_Id,
        codigoPropuesta: data[0].pp_Codigo,
        fechaPropuesta: new Date(data[0].pp_Fecha.toString().slice(0, 23)),

        clienteId: data[0].pp_ClienteId,
        clienteNombre: data[0].cl_Nombre,
        clienteAbrev: data[0].cl_Abrev,
        clienteCategoriaId: data[0].cl_CategoriaId,
        clienteCategoria: data[0].cc_Nombre,

        nombre: data[0].pp_Nombre,
        descripcion: data[0].pp_Descripcion,

        areaId: data[0].pr_AreaId,
        areaNombre: data[0].ua_Abrev + ' | ' + data[0].ua_Nombre,
        tipoTrabajoId: data[0].pp_TipoTrabajoId,
        tipoTrabajoNombre: data[0].tt_Nombre,
        tipoTrabajoOtro: data[0].pp_TipoTrabajoOtro,

        jefeProyectoId: data[0].pp_JefeProyectoId,
        jefeProyectoNombre: data[0].us_pp_jp_Nombre,
        responsableAdministrativoId: data[0].pp_ResponsableAdministrativoId,
        responsableAdministrativoNombre: data[0].us_pp_ra_Nombre,

        rutaCarpeta: data[0].pp_RutaCarpeta,

        codigoProyecto: data[0].pr_Codigo,
        nombreProyecto: data[0].pr_Nombre,
        descripcionProyecto:  data[0].pr_Descripcion,

        fechaProyecto: new Date(data[0].pr_Fecha.toString().slice(0, 23)),
        fechaInicio: new Date(data[0].pr_FechaInicio.toString().slice(0, 23)),
        fechaFin: data[0].pr_FechaFin == null ? null : new Date(data[0].pr_FechaFin.toString().slice(0, 23)),
        meses: monthDiff(new Date(data[0].pr_FechaInicio.toString().slice(0, 23)), data[0].pr_FechaFin == null ? null : new Date(data[0].pr_FechaFin.toString().slice(0, 23))),
        hhProyectadas: data[0].pr_HHProyectadas,

        tipoContratoId: data[0].pr_TipoContratoId,
        tipoMonedaId: data[0].pr_TipoMonedaId,
        tipoContratoNombre: null,
        tipoMonedaNombre: null,

        importeHonorarios: data[0].pr_ImporteHonorarios,
        importeHonorariosOtras: data[0].pr_ImporteHonorariosOtras,
        importeGastosPrevistos: data[0].pr_ImporteGastosPrevistos,
        importeGastosGenerales: data[0].pr_ImporteGastosGenerales,
        importeImpuestos: data[0].pr_ImporteImpuestos,
        importeTotal: 0,


        siReembolsables: data[0].pr_SiReembolsables,
        siHorasExtras: data[0].pr_SiHorasExtras,
        siICWAfirmado: data[0].pr_SiICWAfirmado,
        
        prJefeProyectoId: data[0].pr_JefeProyectoId,
        prJefeProyectoNombre: data[0].us_jp_Nombre,
        prResponsableAdministrativoId: data[0].pr_ResponsableAdministrativoId,
        prResponsableAdministrativoNombre: data[0].us_ra_Nombre,
        prRevisorTecnicoId: data[0].pr_RevisorTecnicoId,
        prRevisorTecnicoNombre: data[0].us_rt_Nombre,

        equipoTrabajo: [],
        equipo: null,
        id: parseInt(projectId),

        verPropuesta: false

      }

      if (data[0].pr_JefeProyectoId == store.userId || data[0].pr_ResponsableAdministrativoId == store.userId || data[0].pr_RevisorTecnicoId == store.userId || store.role == 'admin')
        buttonOptions.disabled = false


      let index = 0
      checkList.forEach( item => {
        let a = 'pr_' + item.field.charAt(0).toUpperCase() + item.field.slice(1)
        checkList[index].value = data[0][a]
        index++
      })
      titulo.value = 'Proyecto | ' + data[0].pr_Codigo
      if (data[0].pr_Equipo !== null && data[0].pr_Equipo.length > 0) {
        let newArray = []
        for (let i = 0; i <= (data[0].pr_Equipo.length/4)-1; i++) 
          {
            newArray.push( parseInt(data[0].pr_Equipo.substring(i*4,(i+1)*4)) )
          }
        newProject.value.equipoTrabajo = newArray
      }
      loadingVisible.value = false     
      
    })
    .catch(() => { throw 'Problema en la Conexión de Red' + url})
  }
  else
    buttonOptions.disabled = false
} )

function updateTareaRow(e) { 
    if (!e.cancel) {
        for (var property in e.oldData) {
        if (!Object.prototype.hasOwnProperty.call(e.newData,property)) {
            e.newData[property] = e.oldData[property];
        }    
        }
        updateTareas.value = e.newData
    }  
}

function onNewTareaRow(e) {
  e.data.pt_ProjectId = selectedItemKeys.value.te_ProjectId
}

function onAddButtonClick(e) {
  selectedItemKeys.value = e.row.data
  myDataEquipo.value.instance.addRow()
}
</script>

<style >

input[readonly] {
  color: rgb(210, 110, 59, 0.7)
}

.dx-form-group-caption {
  color: rgb(210, 110, 59)
}

.dx-form-group-with-caption > .dx-form-group-content {
  padding: 10px 0px 5px 0px;
}

.datospropuesta {
  padding: 10px;
  background-color: rgba(191, 191, 191, 0.15);
}

.lblcheckbox .dx-field-item-label-text {
    color: #8899AE;
    font-size: 12px;
}

.btn-toolbar {
  color: rgb(119, 121, 131);
  background-color: #ffffff;
  border-color: rgb(243,112,32);
  margin-top: 8px;
  height: 32px;
}

.btn-toolbar:hover,
.btn-toolbar:active,
.btn-toolbar:focus,
.btn-toolbar.active {
  background: rgb(243,112,32);
  color: #ffffff;
  border-color: rgb(243,112,32);
}


</style>