<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>


      <!-- v-if="title" -->

      <dx-item

        location="before"
        css-class="header-title"
      >
      <div class="ms-3 h5">{{ store.title }} </div> 
      <!-- <div>{{ title+'@' }} </div> -->
         <!-- <div class="">{{ dataUser}}</div> -->
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
      <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel :user="dataUser" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>
      
      <template #menuUserItem>
        <user-panel
          :user="dataUser"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script setup>

// const props = defineProps({
//     data: { type: Object},
//     chart: {type: Array},
//     muestraData: {type: Boolean},
//     resumen: {type: Boolean}
// })



import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";

import { Auth } from 'aws-amplify'
import router from '../router.js'
import store from '../store/index.js'

// import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

import UserPanel from "./user-panel";

// export default {
//   props: {
//     menuToggleEnabled: Boolean,
//     title: String,
//     toggleMenuFunc: Function,
//     logOutFunc: Function
//   },
//   setup() {

//     // const titulo = store.title

//     const dataUser = ref(store.nombre);
//     const userMenuItems = [{
//         text: "Datos del Usuario",
//         icon: "user",
//         onClick: onProfileClick
//       },
//       {
//         text: "Salir",
//         icon: "bi bi-box-arrow-right",
//         onClick: onLogoutClick
//     }];
      
//     function onLogoutClick() {
//       Auth.signOut()
//       router.push('/auth')
//       Auth.currentAuthenticatedUser().then(() => {
//         console.log('Usuario NO logueado !!!')
//       })
//     }

//     function onProfileClick() {
//       router.push('/profile')
//     }

//     return {
//       dataUser,
//       userMenuItems
//     };
//   },
//   components: {
//     DxButton,
//     DxToolbar,
//     DxItem,
//     UserPanel,
//   }
// };


  defineProps({
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  })

    // const titulo = store.title

    const dataUser = ref(store.nombre);
    const userMenuItems = [{
        text: "Datos del Usuario",
        icon: "user",
        onClick: onProfileClick
      },
      {
        text: "Salir",
        icon: "bi bi-box-arrow-right",
        onClick: onLogoutClick
    }];
      
    function onLogoutClick() {
      Auth.signOut()
      router.push('/auth')
      Auth.currentAuthenticatedUser().then(() => {
        console.log('Usuario NO logueado !!!')
      })
    }

    function onProfileClick() {
      router.push('/profile')
    }

</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}


</style>
